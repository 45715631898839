import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: '/',
    routes: [
        // 主要页面
        {
            path: '/',
            name: 'mainLayout',
            component: () => import('@/views/layout/layout'),
            children: [
                // 学生-我的实验
                {
                    path: 'student/expList',
                    name: 'studentExpList',
                    component: () => import('@/views/student/list'),
                },
                // 学生-实验须知
                {
                    path: 'student/know',
                    name: 'studentKnow',
                    component: () => import('@/views/student/know'),
                },
                // 学生-消息通知
                {
                    path: 'student/message',
                    name: 'studentMessage',
                    component: () => import('@/views/student/message'),
                },
                // 学生、教师-个人信息
                {
                    path: '/',
                    name: 'userInfo',
                    component: () => import('@/views/userInfo/userInfo'),
                },
                // 学生、教师-个人信息
                {
                    path: 'userInfo',
                    name: 'userInfo',
                    component: () => import('@/views/userInfo/userInfo'),
                },
                // 学生-EXE测试
                {
                    path: 'student/exeTest',
                    name: 'studentExeTest',
                    component: () => import('@/views/student/exeTest'),
                },
                // 教师-班级管理
                {
                    path: 'teacher/clazz',
                    name: 'teacherClazz',
                    component: () => import('@/views/teacher/clazz'),
                },
                // 教师-班级详情
                {
                    path: 'teacher/clazzInfo',
                    name: 'teacherClazzInfo',
                    component: () => import('@/views/teacher/clazzInfo'),
                },
                // 教师-实验课程安排
                {
                    path: 'teacher/experiment',
                    name: 'teacherExperiment',
                    component: () => import('@/views/teacher/experiment'),
                },
                // 教师-实验课程安排-详情和新增
                {
                    path: 'teacher/experimentInfo',
                    name: 'teacherExperimentInfo',
                    component: () => import('@/views/teacher/experimentInfo'),
                },
                // 教师-成绩管理
                {
                    path: 'teacher/score',
                    name: 'teacherScore',
                    component: () => import('@/views/teacher/score'),
                },
                // 教师-成绩管理
                {
                    path: 'teacher/scoreInfo',
                    name: 'teacherScoreInfo',
                    component: () => import('@/views/teacher/scoreInfo'),
                },
                // 教师-成绩统计
                {
                    path: 'teacher/scoreStatistic',
                    name: 'teacherScoreStatistic',
                    component: () => import('@/views/teacher/scoreStatistic'),
                },
                // 教师-消息和实验须知
                {
                    path: 'teacher/message',
                    name: 'teacherMessage',
                    component: () => import('@/views/teacher/message'),
                },
                // 教师-看板管理
                {
                    path: 'teacher/dashboard',
                    name: 'teacherDashboard',
                    component: () => import('@/views/teacher/dashboard'),
                },
                // 教师-官网管理
                {
                    path: 'teacher/webSetting',
                    name: 'teacherWebSetting',
                    component: () => import('@/views/teacher/webSetting'),
                },
                // 教师-课堂考核
                {
                    path: 'teacher/exam',
                    name: 'teacherExam',
                    component: () => import('@/views/teacher/exam'),
                },
                // 教师-课堂考核-题目列表
                {
                    path: 'teacher/questionList',
                    name: 'teacherExamQuestionList',
                    component: () => import('@/views/teacher/questionList.vue'),
                },
                // 教师-课堂考核-考卷编辑
                {
                    path: 'teacher/paperEdit',
                    name: 'teacherExamPaperEdit',
                    component: () => import('@/views/teacher/paperEdit.vue'),
                },
                // 教师-课堂考核-考试卷、预览卷
                {
                    path: 'teacher/examPaper',
                    name: 'teacherExamPaper',
                    component: () => import('@/views/teacher/examPaper.vue'),
                },
                // 教师-课堂考核-单体考核模式
                {
                    path: 'teacher/examSingle',
                    name: 'teacherExamSingle',
                    component: () => import('@/views/teacher/examSingle.vue'),
                },
                // 教师-其他-成都大学-接口开放
                {
                    path: 'teacher/cddx_open',
                    name: 'teacherCddxOpen',
                    component: () => import('@/views/teacher/other_cddx/cddx_open.vue'),
                },
                // 教师-其他-成都大学-课堂考核
                {
                    path: 'teacher/cddx_exam',
                    name: 'teacherCddxExam',
                    component: () => import('@/views/teacher/other_cddx/cddx_exam.vue'),
                },
                // 教师-其他-成都大学-全景
                {
                    path: 'teacher/cddx_quan',
                    name: 'teacherCddxQuan',
                    component: () => import('@/views/teacher/other_cddx/cddx_quan.vue'),
                },
            ]
        },
        // 登录
        {
            path: '/login',
            name: 'loginView',
            component: () => import('@/views/login/login'),
        },
        // 官网-雅安职业技术学院
        {
            path: '/yazyjsxy',
            name: 'web_yazyjsxy',
            component: () => import('@/views/web/yazyjsxy'),
        },
        // 官网-重庆城市管理职业技术学院
        {
            path: '/cqcsglzyxy',
            name: 'web_cqcsglzyxy',
            component: () => import('@/views/web/cqcsglzyxy'),
        },
        // 官网-北京青年政治学院
        {
            path: '/bjqnzzxy',
            name: 'web_bjqnzzxy',
            component: () => import('@/views/web/bjqnzzxy'),
        },
        // 官网-湖北铁道运输职业学院
        {
            path: '/hbtdyszyxy',
            name: 'web_hbtdyszyxy',
            component: () => import('@/views/web/hbtdyszyxy'),
        },
        // 官网-广州番禺职业技术学院
        {
            path: "/gzpyzyjsxy",
            name: "web_gzpyzyjsxy",
            component: () => import('@/views/web/gzpyzyjsxy'),
        },
        // 官网-成都汽车职业技术学校
        {
            path: "/cdqczx",
            name: "web_cdqczx",
            component: () => import('@/views/web/cdqczx'),
        },
        // 官网-成都航空职业技术学院
        {
            path: '/cdhkzyjsxy',
            name: 'web_cdhkzyjsxy',
            component: () => import('@/views/web/cdhkzyjsxy'),
        },
        // 官网-成都工业职业技术学院
        {
            path: '/cdgyzyjsxy',
            name: 'web_cdgyzyjsxy',
            component: () => import('@/views/web/cdgyzyjsxy'),
        },
        // 官网-华北水利水电大学
        {
            path: '/hbslsddx',
            name: 'web_hbslsddx',
            component: () => import('@/views/web/hbslsddx'),
        },
        // 官网-广东科贸职业学院
        {
            path: '/gdkmzyxy_241128',
            name: 'web_gdkmzyxy',
            component: () => import('@/views/web/gdkmzyxy'),
        },
        // 官网-扬州工业职业技术学院
        {
            path: '/yzgyzyjsxy',
            name: 'web_yzgyzyjsxy',
            component: () => import('@/views/web/yzgyzyjsxy'),
        },
        // 官网-陆军军事交通学院镇江校区
        {
            path: '/ljjsjtxyzjxq',
            name: 'web_ljjsjtxyzjxq',
            component: () => import('@/views/web/ljjsjtxyzjxq'),
        },
        // 官网-长沙职业技术学院
        {
            path: '/cszyjsxy',
            name: 'web_cszyjsxy',
            component: () => import('@/views/web/cszyjsxy'),
        },
        // 官网-广安职业技术学院
        {
            path: '/gazyjsxy',
            name: 'web_gazyjsxy',
            component: () => import('@/views/web/gazyjsxy'),
        },
        // 官网-石嘴山工贸职业技术学院
        {
            path: '/szsgmzyjsxy',
            name: 'web_szsgmzyjsxy',
            component: () => import('@/views/web/szsgmzyjsxy'),
        },
        // 官网-四川信息职业技术学院
        {
            path: '/scxxzyjsxy',
            name: 'web_scxxzyjsxy',
            component: () => import('@/views/web/scxxzyjsxy'),
        },
        // 官网-西南财经大学
        {
            path: '/xncjdx',
            name: 'web_xncjdx',
            component: () => import('@/views/web/xncjdx'),
        },
        // 官网-演示学校
        {
            path: '/ysxy',
            name: 'web_ysxy',
            component: () => import('@/views/web/ysxy'),
        },
        // 官网-重庆工贸职业技术学院
        {
            path: '/cqgmzyjsxy',
            name: 'web_cqgmzyjsxy',
            component: () => import('@/views/web/cqgmzyjsxy'),
        },
        // 官网-四川邮电职业技术学院
        {
            path: '/scydzyjsxy',
            name: 'web_scydzyjsxy',
            component: () => import('@/views/web/scydzyjsxy'),
        },
        // 看板-V1-主页
        {
            path: "/dashboard/v1/index",
            name: "dashboard_v1_index",
            component: () => import('@/views/dashboard/v1Index')
        },
        // 看板-V1-班级
        {
            path: "/dashboard/v1/class",
            name: "dashboard_v1_class",
            component: () => import('@/views/dashboard/v1Class')
        },
        // 看板-V2-主页
        {
            path: "/dashboard/v2/index",
            name: "dashboard_v2_index",
            component: () => import('@/views/dashboard/v2Index')
        },
        // 看板-V2-班级
        {
            path: "/dashboard/v2/class",
            name: "dashboard_v2_class",
            component: () => import('@/views/dashboard/v2Class')
        },
        // 看板-V2-图表页
        {
            path: "/dashboard/v2/chart",
            name: "dashboard_v2_chart",
            component: () => import('@/views/dashboard/v2Chart')
        },
        // 官网-新疆农业职业技术学院
        {
            path: '/xjnyzyjsxy',
            name: 'web_xjnyzyjsxy',
            component: () => import('@/views/web/xjnyzyjsxy'),
        },
        // 官网-福建信息职业技术学院
        {
            path: '/fjxxzyjsxy',
            name: 'web_fjxxzyjsxy',
            component: () => import('@/views/web/fjxxzyjsxy'),
        },
        // 官网-云南国土资源职业学院
        {
            path: '/yngtzyzyxy',
            name: 'web_yngtzyzyxy',
            component: () => import('@/views/web/yngtzyzyxy'),
        },
        // 网页跳转-福建信息职业技术学院-苏区精神
        {
            path: '/fjxxzy_sqjs',
            name: 'web_fjxxzy_sqjs',
            component: () => import('@/views/web/fjxxzy_sqjs.vue'),
        },
        // 官网-重庆青年职业技术学院
        {
            path: '/cqqnzyjsxy',
            name: 'web_cqqnzyjsxy',
            component: () => import('@/views/web/cqqnzyjsxy'),
        },
        // 官网-重庆公共运输职业学院
        // {
        //     path: '/cqggyszyxy',
        //     name: 'web_cqggyszyxy',
        //     component: () => import('@/views/web/cqggyszyxy'),
        // },
        // 官网-西财-240914申报
        {
            path: '/xc',
            name: 'web_xc',
            component: () => import('@/views/web/xc'),
        },
        // 官网-太原学院
        {
            path: '/tyxy',
            name: 'web_tyxy',
            component: () => import('@/views/web/tyxy'),
        },
        // 官网-天津铁道职业技术学院
        {
            path: '/tjtdzyjsxy',
            name: 'web_tjtdzyjsxy',
            component: () => import('@/views/web/tjtdzyjsxy'),
        },
        // 其他路由
        {path: '*', component: () => import('@/views/page404')}
    ]
});
