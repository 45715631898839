/**
 * 配置信息
 * 各类常量
 */

// api地址-erp后端
// 本地
// const API_URL_ERP = "http://127.0.0.1:8900/"
// 测试服务器
// const API_URL_ERP = "http://dashtest.xhyjbj.com/erp/"
// 正式服务器
const API_URL_ERP = "https://api.cdzyhd.com/system/erp/"

// api地址-exp后端-Admin
// 本地
// const API_URL_EXP_ADMIN = "http://127.0.0.1:9900/"
// 测试服务器
// const API_URL_EXP_ADMIN = "http://exp-test.xhyjbj.com/admin/"
// 正式服务器
const API_URL_EXP_ADMIN = "https://api.cdzyhd.com/system/exp_admin/"


// api地址-exp后端-Consumer
// 本地
// const API_URL_EXP_CONSUMER = "http://127.0.0.1:9902/"
// 测试服务器
// const API_URL_EXP_CONSUMER = "http://exp-test.xhyjbj.com/consumer/"
// 正式服务器
const API_URL_EXP_CONSUMER = "https://api.cdzyhd.com/system/exp_consumer/"

// 登录页地址
const LOGIN_URL_SESSION_KEY = "exp_loginUrl"

// ERP后台地址
const URL_ERP = "http://erp.cdzyhd.com"

// 平台编号
const PLATFORM_ID = "exp" // 正式服务器
// const PLATFORM_ID = "exp_test" // 测试服务器

// 配置名称-EXP
const CONFIG_NAME_EXP="config_exp" // 正式
// const CONFIG_NAME_EXP = "config_exp_test" // 测试

// 官网前台地址
const OFFICIAL_WEB_URL="https://www.cdzyhd.com";
const OFFICIAL_H5_WEB_URL="http://h5.cdzyhd.com";

// 移动端地址
const H5_URL="http://h5.exp.cdzyhd.com"

// 课堂考核H5地址
// const EXAM_H5_URL="http://exp-exam-test.xhyjbj.com/"
const EXAM_H5_URL="http://h5-exam.exp.cdzyhd.com/"

export {H5_URL,OFFICIAL_WEB_URL,OFFICIAL_H5_WEB_URL,API_URL_ERP, API_URL_EXP_ADMIN, API_URL_EXP_CONSUMER, PLATFORM_ID,
    LOGIN_URL_SESSION_KEY, CONFIG_NAME_EXP,URL_ERP,EXAM_H5_URL}
